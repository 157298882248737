<template>
    <v-card class="fill-height" fluid>
        <v-toolbar dark color="primary">
            <router-link :to="{ path: '/' }" tag="v-btn">
                <v-btn icon dark>
                    <v-icon>close</v-icon>
                </v-btn>
            </router-link>
            <v-toolbar-title>{{ $t("Meus dados") }}</v-toolbar-title>
        </v-toolbar>
        <v-card class="mx-auto mt-4" max-width="800" color="accent" dark>
            <v-card-title>
                <v-icon large left> settings_applications </v-icon>
                <span class="title font-weight-light">{{ $t("Preferências") }}</span>
            </v-card-title>
            <v-card-text>
                <div class="my-2">
                    <v-alert v-if="notificationStatus == 1" type="success">{{ $t("Notificação habilitada.") }}</v-alert>
                    <v-alert v-else-if="notificationStatus == -2" type="error">{{ $t("Dispositivo não suporta notificação.") }}</v-alert>
                    <v-alert v-else-if="notificationStatus == -1" type="error">
                        {{ $t("Notificações bloqueadas pelo usuário. Para habilitar verifique as configurações do seu navegador.") }}
                    </v-alert>
                    <v-btn v-else x-large color="success" @click="notifyMe" dark :disable="saving">{{ $t("Habilitar notificação") }}</v-btn>

                    <!-- <v-btn color="success" @click="sendPreferences" :loading="saving" :disable="saving">{{ $t("Salvar") }}</v-btn> -->
                </div>
            </v-card-text>
        </v-card>

        <v-card class="mx-auto mt-4" max-width="800" color="accent" dark>
            <v-card-title>
                <v-icon large left> vpn_key </v-icon>
                <span class="title font-weight-light">{{ $t("Alterar senha") }}</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" @submit.prevent="send">
                    <v-text-field
                        class="my-8"
                        :label="$t('Digite a senha atual')"
                        v-model="input.oldPassword"
                        min="8"
                        :rules="[rules.required]"
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                        counter
                        required
                    />

                    <v-text-field
                        :label="$t('Digite a nova senha')"
                        v-model="input.newPassword"
                        min="8"
                        :rules="[rules.required, rules.password]"
                        :append-icon="show2 ? 'visibility' : 'visibility_off'"
                        :type="show2 ? 'text' : 'password'"
                        @click:append="show2 = !show2"
                        counter
                        required
                    />
                    <v-text-field
                        :label="$t('Confirme a nova senha')"
                        v-model="confirmPassword"
                        min="8"
                        :rules="confirmPasswordRules"
                        :append-icon="show3 ? 'visibility' : 'visibility_off'"
                        :type="show3 ? 'text' : 'password'"
                        @click:append="show3 = !show3"
                        counter
                        required
                    />
                    <v-btn color="success" @click="send" :disabled="!valid" :loading="saving" :disable="saving">{{ $t("Salvar") }}</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>
import rules from "@/helpers/rules";

export default {
    name: "Register",
    mounted() {
        this.checkNotificationStatus();
    },
    data() {
        return {
            rules,
            saving: false,
            valid: true,
            confirmPassword: null,
            notificationStatus: -1,
            show1: false,
            show2: false,
            show3: false,
            input: {
                newPassword: null,
                oldPassword: null,
            },
            confirmPasswordRules: [(v) => v === this.input.newPassword || this.$t("Senhas diferentes")],
        };
    },
    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.saving = true;
                this.$http
                    .put("auth", this.$store.state.user.ID, this.input)
                    .then((result) => {
                        this.saving = false;
                        this.$store.commit("CHANGE_PASSWORD", this.input.newPassword);
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Senha alterada com sucesso."));
                    })
                    .catch((error) => {
                        this.saving = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Não foi possível enviar sua solicitação."));
                    });
            }
        },
        sendPreferences() {},
        checkNotificationStatus() {
            if (!("Notification" in window)) {
                this.notificationStatus = -2;
            } else if (Notification.permission === "granted") {
                this.notificationStatus = 1;
            } else if (Notification.permission !== "denied") {
                this.notificationStatus = 0;
            } else {
                this.notificationStatus = -1;
            }
        },
        notifyMe() {
            Notification.requestPermission().then(() => {
                this.checkNotificationStatus();
            });
        },
    },
};
</script>